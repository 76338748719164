import { ref } from 'vue'
import { defineStore } from 'pinia'

import { fetchLastTravellerTravellerFolder } from '@shared/http/api'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useTravellerFoldersStore = defineStore('traveller_folders', () => {
  const activeFolder = ref(false)

  async function getActiveFolder() {
    await fetchLastTravellerTravellerFolder()
      .then((response) => {
        activeFolder.value = response.data.data
      })
  }

  return {
    activeFolder,
    getActiveFolder,
  }
})
